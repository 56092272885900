<template>
  <!-- 五金登记 -->
  <div>
    <div class="wujinCheckIn_main">
      <van-nav-bar title="五金登记"
                   left-arrow
                   @click-left="onClickLeft"
                   :fixed="true" />
      <div class="content"
           :style="viewType != '3' && viewType != '5'?'height: calc(100% - 2.293334rem);':'height: calc(100% - 48px);'">
        <div class="form_and_table">
          <van-form id="main_form">
            <van-field class="not_canedi"
                       :label="'工号'"
                       v-model="form.empcode"
                       readonly />
            <van-field class="not_canedi"
                       :label="'姓名'"
                       v-model="form.empname"
                       readonly />
            <!-- <van-field class="not_canedi"
                       :label="'需求分类'"
                       v-model="form.xqfl"
                       readonly /> -->
            <van-field class="not_canedi"
                       :label="'工件'"
                       v-model="form.gj"
                       readonly />
            <van-field class="not_canedi"
                       :label="'组件编码'"
                       v-model="form.zjcode"
                       readonly />
            <!-- <van-field class="not_canedi"
                       :label="'计划日期'"
                       v-model="form.yymmdd"
                       readonly /> -->
            <van-field class="not_canedi"
                       :label="'录入日期'"
                       v-model="form.crdate"
                       readonly />
            <!-- <van-field class="not_canedi"
                       :label="'出货数'"
                       v-model="form.chs"
                       readonly /> -->
          </van-form>
          <div class="isSon_body"
               v-if="autoid">
            <!-- 子表页签Tab -->
            <div class="son_tab">
              生产明细
            </div>
            <!-- <van-tabs v-model="activeSon"
                      @change="changTab">
              <van-tab title="生产明细"
                       name="1"> </van-tab>
            </van-tabs> -->
            <!-- 隐藏按钮 20240309 -->
            <!-- <div class="sonTable_btn"
                 v-if="showSonBtn && canEdit">
                <van-button type="primary"
                          size="small"
                          @click="goToAdd">新增</van-button>  
                <van-button type="default"
                          size="small"
                          @click="goToEdit">修改</van-button>  
              <van-button type="warning"
                          size="small"
                          @click="goToDelete">删除</van-button>
            </div> -->
            <div class="sontable_box">
              <table ref="table"
                     align="center"
                     class="table">
                <!-- 表头 -->
                <!-- :style="item_th.gridwith ? { width: item_th.gridwith * 1.5 + 'px' } : 'min-width:90px'" -->
                <tr class="top">
                  <th v-for="(item_th, index_th) in auditThList"
                      :key="index_th">
                    <div class="title">
                      {{ item_th.chnname }}
                    </div>
                  </th>
                </tr>
                <!--展示列表数据 -->
                <!-- @click.stop="changeAuditDataByTd(item_tr)" -->
                <tr class="cont"
                    v-for="(item_tr, index_tr) in auditTableData"
                    :key="index_tr">
                  <td v-for="(item_td, index_td) in auditThList"
                      :key="index_td">
                    <div class="comm_box">
                      <div class="boolean_box"
                           v-if="item_td.datatype == 'checkbox'"
                           @click.stop="changeAuditDataByTd(item_tr)">
                        <van-checkbox v-model="item_tr[item_td.fieldname]"></van-checkbox>
                      </div>
                      <div class="boolean_box"
                           v-else-if="item_td.datatype == 'checkbox_disable'">
                        <van-checkbox v-model="item_tr[item_td.fieldname]"
                                      shape="square"
                                      disabled></van-checkbox>
                      </div>
                      <div class="input_box"
                           v-else-if="item_td.datatype == 'num'">
                        <van-field v-model="item_tr[item_td.fieldname]"
                                   :placeholder="item_td.chnname" />
                      </div>
                      <div class="btn_box"
                           v-else-if="item_td.datatype == 'btn'">
                        <van-button type="info"
                                    size="mini"
                                    @click.stop="editToolsNum(item_tr)">{{item_td.btnStr}}</van-button>
                      </div>
                      <div v-else>{{ item_tr[item_td.fieldname] }}</div>
                    </div>
                  </td>
                </tr>
                <tr class="nodata"
                    v-if="auditTableData.length == 0">
                  <td align="center"
                      :colspan="auditThList.length">暂无数据</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div class="footerBox"
           v-if="viewType != '3' && viewType != '5'">
        <div class="flexBox"
             v-if="canEdit">
          <van-button v-if="viewType != '2'"
                      @click="staging"
                      type="primary"
                      style="width: 45%;height: 100%;">暂存</van-button>
          <van-button @click="submit"
                      type="info"
                      style="width: 45%;height: 100%;">提交</van-button>
        </div>
        <div class="flexBox"
             v-else>
          <van-button @click="agree"
                      type="primary"
                      style="width: 45%;height: 100%;">同意</van-button>
          <van-button @click="disagree"
                      type="info"
                      style="width: 45%;height: 100%;">不同意</van-button>
        </div>
      </div>
    </div>
    <!-- 生产明细编辑弹窗 -->
    <van-popup v-model="showEditSC"
               round
               :style="{ height: '60%' , width: '90%'}"
               class="editSC_main_pop">
      <van-nav-bar title="生产明细"
                   :fixed="true">
        <template #right>
          <van-icon name="cross"
                    size="18"
                    color="#ffffff"
                    @click="showEditSC = false" />
        </template>
      </van-nav-bar>
      <div class="content">
        <van-form>
          <!-- @click="showGxPicker = true" -->
          <van-field required
                     readonly
                     clickable
                     label="工序"
                     right-icon="edit"
                     v-model="scForm.toolsname"
                     :placeholder="$t('module.PleaseChoose')" />
          <van-field :required="true"
                     :label="'工序数量'"
                     type="number"
                     right-icon="edit"
                     v-model="scForm.toolsnum"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field :label="'备注'"
                     disabled
                     v-model="scForm.remark"
                     :required="false"
                     right-icon="edit"
                     rows="5"
                     autosize
                     type="textarea"
                     :placeholder="$t('module.PleaseEnter')" />
        </van-form>
        <div class="footerBox">
          <div class="flexBox">
            <van-button @click="confirmSC"
                        type="info"
                        style="width: 80%;height: 100%;">确定</van-button>

          </div>
        </div>
      </div>
    </van-popup>
    <!-- 工序下拉弹窗 -->
    <van-popup v-model="showGxPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="gxcolumns"
                  value-key="toolsname"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  @cancel="showGxPicker = false"
                  @confirm="onConfirmGx">
        <template #title>
          <van-search shape="round"
                      v-model="searchGX"
                      placeholder="搜索关键词"
                      @input="searchGXList" />
        </template>
      </van-picker>
    </van-popup>

    <!-- 订单工序明细编辑弹窗 -->
    <van-popup v-model="showOrderDetails"
               round
               :style="{ height: '60%' , width: '90%'}"
               class="OrderDetails_main_pop">
      <van-nav-bar :title="'工序明细--'+form.gj"
                   :fixed="true">
        <template #right>
          <van-icon name="cross"
                    size="18"
                    color="#ffffff"
                    @click="showOrderDetails = false" />
        </template>
      </van-nav-bar>
      <div class="content">
        <div class="sontable_box">
          <table ref="table"
                 align="center"
                 class="table">
            <!-- 表头 -->
            <tr class="top">
              <th v-for="(item_th, index_th) in orderDetailsThList"
                  :key="index_th">
                <div class="title"
                     :style="item_th.gridwith ? { width: item_th.gridwith * 1.5 + 'px' } : 'min-width:90px'">
                  {{item_th.chnname}}
                </div>
              </th>
            </tr>
            <!--展示列表数据 -->
            <tr class="cont"
                v-for="(item_tr, index_tr) in orderDetailTableData"
                :key="index_tr">
              <td v-for="(item_td, index_td) in orderDetailsThList"
                  :key="index_td">
                <div v-if="item_td.datatype == 'ipt'"
                     class="input_box">
                  <van-field v-model="item_tr[item_td.fieldname]"
                             type="number"
                             :placeholder="$t('module.PleaseEnter')" />
                </div>
                <div v-else
                     class="input_box">
                  <div>{{ item_tr[item_td.fieldname] }}</div>
                </div>
              </td>
            </tr>
            <!-- 最后一行的合计数据 需要高亮 -->
            <tr class="cont sum"
                style="background:#ffa07a; position: sticky;bottom: 0;">
              <td v-for="(item, index) in totalData"
                  :key="index">
                {{ item }}
              </td>
            </tr>
            <tr class="nodata"
                v-if="orderDetailTableData.length == 0">
              <td align="center"
                  :colspan="orderDetailsThList.length">暂无数据</td>
            </tr>
          </table>
        </div>
        <div class="footerBox">
          <div class="flexBox">
            <van-button @click="confirmOrderDetails"
                        type="info"
                        style="width: 80%;height: 100%;">提交</van-button>

          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import {
  getWjBarCodeInfo,
  getWjGxList,
  saveWjInputData,
  getWjInputData,
  addWjScmxRecord,
  saveWjScmxRecord,
  deleteWjScmxRecord,
  postWjInputData,
  agreeTmList,
  notAgreeTmlist,
  getWjOrderDetail,
  saveWjOrderDetail
} from "@api/wxjj.js";
export default {
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    return {
      userInfo,
      moduleno: 821,
      viewType: 0, // 1待审批 2待提交 3已审批 4不同意
      canEdit: true,
      autoid: 0,
      form: {
        autoid: null,
        empcode: '',
        empname: '',
        yymmdd: '', // --生产日期 
        xqfl: '',
        gj: '',
        zjcode: '',
        username: '', //  
        crdate: this.formatDate(new Date(), 'yyyy-MM-dd'),
        chs: ''
      },
      activeSon: '1',
      auditThList: [], // 表头
      auditTableData: [], // 表格数据
      initAuditTableData: [], // // 表格数据初始数据
      showSonBtn: true,
      scmx_data: [], // 生产明细 
      /*** 生产明细子表弹窗参数-----------start */
      showEditSC: false, // 生产明细弹窗
      editType: 'add',
      scForm: {
        mautoid: null,
        toolsid: '', // 工序id
        toolsname: '',  // --工序名称
        toolsnum: '', // 工序数量
        remark: '', // --未完成原因
        username: ''
      },
      showGxPicker: false,
      gxcolumns: [],
      searchGX: '', // 工序下拉搜索值
      /*** 生产明细子表弹窗参数-----------end */
      orderDetailTableData: [],
      showOrderDetails: false,
      orderDetailsThList: [
        {
          fieldname: "sqlNum",
          chnname: "序号",
          gridwith: 30,
          datatype: 'str',
          footerkind: 'skNone'
        },
        {
          fieldname: "orderid",
          chnname: "订单编号",
          gridwith: 80,
          datatype: 'str',
          footerkind: 'skNone'
        },
        {
          fieldname: "ordernum",
          chnname: "出货数/台",
          gridwith: 60,
          datatype: 'str',
          footerkind: 'skSum'
        },
        {
          fieldname: "yl",
          chnname: "支数",
          gridwith: 60,
          datatype: 'str',
          footerkind: 'skSum'
        },
        {
          fieldname: "lsnum",
          chnname: "历史录入数量",
          gridwith: 60,
          datatype: 'str',
          footerkind: 'skSum'
        },
        {
          fieldname: "wwcnum",
          chnname: "未完成数量",
          gridwith: 60,
          datatype: 'str',
          footerkind: 'skSum'
        },
        {
          fieldname: "toolsnum",
          chnname: "工序数量",
          gridwith: 60,
          datatype: 'ipt',
          footerkind: 'skSum'
        },
      ],
      totalData: []
    }
  },
  watch: {
    showGxPicker (n) {
      if (n)
      {
        this.getWjGxListData();
      } else
      {
        this.searchGX = ''
      }
    },
    orderDetailTableData: {
      handler (n) {
        this.calculateTotalRows()
      },
      deep: true
    }
  },
  mounted () {
    // // 1待审批 2待提交 3已审批 4不同意
    if (this.$route.query.type)
    {
      this.viewType = this.$route.query.type
      if (this.viewType == '1' || this.viewType == '3' || this.viewType == '5')
      {
        this.canEdit = false;
      }
    }
    if (this.$route.query.autoid)
    {
      this.autoid = this.$route.query.autoid
      this.getWjInputDataInfo()
    }
    // 从外部跳转进来获取默认值
    if (this.$route.query.ptid)
    {
      this.getWjBarCodeInfoData(this.$route.query.ptid)
    }
    this.auditThList = [
      {
        fieldname: "sqlNum",
        chnname: "序号",
        gridwith: 30,
        datatype: 'str'
      },
      {
        fieldname: "toolsname",
        chnname: "工序名称",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "toolsnum",
        chnname: "工序数量",
        gridwith: 60,
        datatype: 'btn',
        btnStr: '登记数量'
      }
    ]
  },
  methods: {
    // 计算表格合计行
    calculateTotalRows () {
      this.totalData = []
      this.totalData.push('汇总')
      this.orderDetailsThList.forEach(it => {
        if (it.fieldname != 'sqlNum')
        {
          if (it.footerkind != 'skNone')
          {
            let result = ''
            const values = this.orderDetailTableData.map(item =>
              parseFloat(item[it.fieldname])
            )
            if (!values.every(value => isNaN(value)))
            {
              switch (it.footerkind)
              {
                case 'skAverage':
                  result = this.computedAverage(values)
                  break
                case 'skCount':
                  result = this.computedCount(values)
                  break
                case 'skMax':
                  result = this.computedMax(values)
                  break
                case 'skMin':
                  result = this.computedMin(values)
                  break
                case 'skSum':
                  let tol = this.computedSum(values)
                  result = Math.round(tol * 100) / 100
                  break
              }
              result = String(result)
              const dot = result.indexOf('.')
              if (dot > -1)
              {
                result = result.slice(0, dot + 3)
              } else
              {
                result = result + '.00'
              }
            }
            this.totalData.push(result)
          } else
          {
            if (it.gridwith && it.gridwith != 0)
            {
              this.totalData.push('')
            }
          }
        }
      })
    },
    // 平均值
    computedAverage (arr) {
      return Math.floor((sum(arr) * 1000) / arr.length) / 1000
    },
    // 整数
    computedCount (arr) {
      return arr.length
    },
    // 最大值
    computedMax (arr) {
      return Math.max(...arr)
    },
    // 最小值
    computedMin (arr) {
      return Math.min(...arr)
    },
    // 求和
    computedSum (arr) {
      return arr.reduce((prev, curr) => {
        const value = Number(curr)
        if (!isNaN(value))
        {
          return prev + curr
        } else
        {
          return prev
        }
      }, 0)
    },
    // 登记工序数量提交
    confirmOrderDetails () {
      let list = this.orderDetailTableData.map(it => { return { autoid: it.autoid, toolsnum: it.toolsnum } })
      saveWjOrderDetail({
        username: this.userInfo.username,
        data: list
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info)
        } else
        {
          Toast.success(this.$t('module.submitScu'))
          this.showOrderDetails = false
        }
      })
    },
    // 登记工序数量
    editToolsNum (item) {
      if (item.autoid)
      {
        // 获取五金订单工序明细接口
        getWjOrderDetail({
          mautoid: item.autoid,
          username: this.userInfo.username
        }).then(res => {
          res.data
          res.data.forEach((el, idx) => {
            el.sqlNum = idx + 1
          });
          this.orderDetailTableData = res.data
          this.showOrderDetails = true
        })
      }
    },
    // 工序选择回调
    onConfirmGx (value) {
      this.scForm.toolsid = value.toolsid
      this.scForm.toolsname = value.toolsname
      this.showGxPicker = false
    },
    // 工序下拉搜索
    searchGXList () {
      if (this.searchGX)
      {
        this.gxcolumns = this.gxcolumns.filter(it => { return it.toolsname.indexOf(this.searchGX) >= 0 })
      } else
      {
        this.getWjGxListData();
      }
    },
    // 工序下拉项目
    getWjGxListData () {
      getWjGxList({
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        this.gxcolumns = res.data
      })
    },
    // 生产明细弹窗确定按钮
    confirmSC () {
      if (!this.scForm.toolsname)
      {
        Toast.fail('工序不能为空！')
        return
      }
      if (!this.scForm.toolsnum)
      {
        Toast.fail('工序数量不能为空！')
        return
      }
      this.scForm.username = this.userInfo.username
      if (this.editType == 'add')
      {
        // 新增
        this.scForm.mautoid = this.autoid
        addWjScmxRecord(this.scForm).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Toast.success('新增成功！')
            this.showEditSC = false
            this.getWjInputDataInfo()
          }
        })
      } else
      {
        // 修改 
        saveWjScmxRecord(this.scForm).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Toast.success('修改成功！')
            this.showEditSC = false
            this.getWjInputDataInfo()
          }
        })
      }
    },
    // 子表新增按钮
    goToAdd () {
      if (this.activeSon == '1')
      {
        // 去生产明细
        this.editType = 'add'
        this.scForm = {
          mautoid: null,
          toolsid: '', // 工序id
          toolsname: '',  // --工序名称
          toolsnum: '', // 工序数量
          remark: '', // --未完成原因
          username: this.userInfo.username
        }
        this.showEditSC = true
      }
    },
    // 子表修改
    goToEdit () {
      const checkedData = this.auditTableData.filter(it => { return it.checked })
      if (checkedData.length != 1)
      {
        Toast.fail('请选择1条记录！');
        return
      }
      if (this.activeSon == '1')
      {
        // 去生产明细
        this.editType = 'edit'
        this.scForm = { ...this.scForm, ...checkedData[0] }
        this.showEditSC = true
      }
    },
    // 子表删除
    goToDelete () {
      const checkedData = this.auditTableData.filter(it => { return it.checked })
      if (checkedData.length == 0)
      {
        Toast.fail('请选择记录！');
        return
      }
      const ids = checkedData.map(it => { return it.autoid }) || []
      let params = {
        autoidlist: ids.join(','),
        username: this.userInfo.username
      }
      if (this.activeSon == '1')
      {
        // 生产明细删除 
        Dialog.confirm({
          // title: '标题',
          message: this.$t('module.sureToDelete')
        }).then(() => {
          deleteWjScmxRecord(params).then(res => {
            if (res.data[0].info)
            {
              Toast.fail(res.data[0].info)
            } else
            {
              Toast.success(this.$t('module.DelSuc'))
              this.getWjInputDataInfo()
            }
          })
        }).catch(() => {
          // on cancel
        });
      }
    },
    // 页签切换事件
    changTab (name, title) {
      if (name == '1')
      {
        // 生产明细
        this.showSonBtn = true
        this.auditThList = [
          {
            fieldname: "checked",
            chnname: "",
            gridwith: 10,
            datatype: 'checkbox'
          },
          {
            fieldname: "toolsname",
            chnname: "工序名称",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "toolsnum",
            chnname: "工序数量",
            gridwith: 60,
            datatype: 'str'
          }
        ]
        this.auditTableData = this.scmx_data
      }

    },
    // 点击表格行
    changeAuditDataByTd (val) {
      this.auditTableData.forEach(ele => {
        if (val.autoid == ele.autoid)
        {
          ele.checked = !ele.checked
        }
      });
      this.$forceUpdate()
    },
    // 同意单据
    agree () {
      agreeTmList({
        moduleno: this.moduleno,
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info)
        } else
        {
          Toast.success('审批成功！');
          this.onClickLeft()
        }
      })
    },
    // 不同意单据
    disagree () {
      notAgreeTmlist({
        moduleno: this.moduleno,
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info)
        } else
        {
          Toast.success('审批成功！');
          this.onClickLeft()
        }
      })
    },
    // 总装主表及子表数据
    getWjInputDataInfo () {
      getWjInputData({
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        const info = res.baseinfo[0]
        if (info)
        {
          this.form = { ...this.form, ...info }
        }
        this.scmx_data = res.scmx_data
        if (this.activeSon == '1')
        {
          this.scmx_data.forEach((ele, idx) => {
            ele.checked = false
            ele.sqlNum = idx + 1
          });
          this.auditTableData = this.scmx_data
        }
      })
    },
    // 暂存
    async staging () {
      this.form.empid = this.userInfo.empid
      this.form.username = this.userInfo.username
      this.form.data = this.auditTableData
      try
      {
        return await new Promise((resolve, reject) => {
          saveWjInputData(this.form).then(res => {
            if (res.data[0].info)
            {
              Toast.fail(res.data[0].info);
              reject(false);
            }
            else
            {
              this.form.autoid = res.data[0].autoid;
              this.autoid = res.data[0].autoid;
              Toast.success('暂存成功！');
              this.getWjInputDataInfo();
              resolve(this.autoid);
            }
          })
        });
      } catch (e) { }
    },
    //  提交
    async submit () {
      // if (this.autoid)
      // {
      //   postWjInputData({
      //     autoid: this.autoid,
      //     username: this.userInfo.username
      //   }).then(res => {
      //     if (res.data[0].info)
      //     {
      //       Toast.fail(res.data[0].info)
      //     } else
      //     {
      //       Dialog.alert({
      //         message: '提交成功！',
      //       }).then(() => {
      //         this.onClickLeft()
      //       });
      //     }
      //   })
      // } else
      // {
      this.staging().then(res => {
        if (res)
        {
          postWjInputData({
            autoid: this.autoid,
            username: this.userInfo.username
          }).then(res => {
            if (res.data[0].info)
            {
              Toast.fail(res.data[0].info)
            } else
            {
              Dialog.alert({
                message: '提交成功！',
              }).then(() => {
                this.onClickLeft()
              });
            }
          })
        }
      }).catch(error => {

      })
      // }
    },
    // 获取到产品相关信息
    getWjBarCodeInfoData (id) {
      getWjBarCodeInfo({
        ptid: id,
        username: this.userInfo.username
      }).then(res => {
        const result = res.data[0]
        this.form = { ...this.form, ...result }
      })
    },
    // 回退
    onClickLeft () {
      if (this.$route.query.autoid)
      {
        this.$router.push('/toDoList')
      } else
      {
        this.$router.push('/home')
      }
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)
      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')
        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else if (rule == 'yyyy-MM-dd HH:mm')
        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
        } else if (rule == 'HH:mm')
        {
          return (
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
        } else
        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm) +
            ':' +
            this.add0(s)
          )
        }
      } else
      {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="less">
.OrderDetails_main_pop {
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    height: calc(100% - 96px);
    margin-top: 96px;
    .sontable_box {
      height: calc(100% - 80px);
      overflow-x: auto;
      background: #ffffff;
      // flex: 1;
      .table {
        width: 100%;
        border-spacing: 0px;
        tr {
          td:nth-child(1) {
            border-left: 2px solid #dcdfe6;
          }
          th,
          td {
            padding: 6px 10px;
            text-align: center;
            border-bottom: 2px solid #dcdfe6;
            border-right: 2px solid #dcdfe6;
            // .comm_box {
            //   display: flex;
            //   justify-content: center;
            .input_box {
              width: 100%;
              .van-cell {
                font-size: 24px;
                border: 2px solid #d2d2d2;
                border-radius: 10px;
                padding: 8px 16px;
              }
            }
            // }
          }
        }
        .top {
          position: sticky;
          top: 0;
          font-size: 28px;
          background: #e9f3fd;
          height: 64px;
          z-index: 999;
          th:nth-child(1) {
            // width: 50%;
            border-left: 2px solid #dcdfe6;
          }
          th {
            border-top: 2px solid #dcdfe6;
          }
        }
        .cont {
          height: 64px;
          font-size: 24px;
        }
        .cont:nth-child(2n-1) {
          background: #f5f5f5;
        }
        .nodata {
          td {
            font-size: 28px;
          }
        }
      }
    }
    .footerBox {
      height: 80px;
      background: #fff;
      display: flex;
      align-items: center;
      .flexBox {
        width: 100vw;
        flex-wrap: nowrap;
        overflow: hidden;
        height: 80%;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}

.editSC_main_pop {
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    height: calc(100% - 96px);
    margin-top: 96px;
    /deep/.van-form {
      height: calc(100% - 80px);
      overflow-y: auto;
      .van-field {
        .van-field__label {
          width: 8em;
        }
      }
      .not_canedi {
        .van-field__value {
          .van-field__body {
            .van-field__control {
              color: #999999;
            }
          }
        }
      }
    }
    .footerBox {
      height: 80px;
      background: #fff;
      display: flex;
      align-items: center;
      .flexBox {
        width: 100vw;
        flex-wrap: nowrap;
        overflow: hidden;
        height: 80%;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
.wujinCheckIn_main {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // height: calc(100% - 216px);
    margin-top: 96px;
    overflow-y: auto;
    // display: flex;
    // flex-direction: column;
    .form_and_table {
      // height: calc(100% - 120px);
      // flex: 1;
      // display: flex;
      // flex-direction: column;
      /deep/.van-form {
        margin-bottom: 16px;
        .van-field {
          .van-field__label {
            width: 8em;
          }
        }
        .not_canedi {
          .van-field__value {
            .van-field__body {
              .van-field__control {
                color: #999999;
              }
            }
          }
        }
        .last_cell::after {
          display: block !important;
          border-bottom: 2px solid #ebedf0;
        }
      }
      .isSon_body {
        // flex: 1;
        // display: flex;
        // flex-direction: column;
        .son_tab {
          font-size: 32px;
          background: #fff;
          padding: 0 20px;
          height: 80px;
          line-height: 80px;
        }
        .sonTable_btn {
          display: flex;
          align-items: center;
          background: #ffffff;
          padding: 16px;
          .van-button {
            width: 120px;
            height: 60px;
            margin-right: 16px;
            font-size: 26px;
          }
        }
        .sontable_box {
          overflow-x: auto;
          background: #ffffff;
          // flex: 1;
          .table {
            width: 100%;
            border-spacing: 0px;
            tr {
              td:nth-child(1) {
                border-left: 2px solid #dcdfe6;
              }
              th,
              td {
                padding: 6px 10px;
                text-align: center;
                border-bottom: 2px solid #dcdfe6;
                border-right: 2px solid #dcdfe6;
                .comm_box {
                  display: flex;
                  justify-content: center;
                  .boolean_box {
                    .van-checkbox {
                      justify-content: center;
                      /deep/.van-checkbox__icon--checked {
                        .van-icon {
                          color: #fff !important;
                          background-color: #1989fa !important;
                          border-color: #1989fa !important;
                        }
                      }
                      /deep/.van-checkbox__icon--disabled .van-icon {
                        background-color: #fff;
                      }
                    }
                  }
                  .input_box {
                    width: 150px;
                    .van-cell {
                      font-size: 24px;
                      border: 2px solid #d2d2d2;
                      border-radius: 10px;
                      padding: 8px 16px;
                    }
                  }
                }
              }
            }
            .top {
              position: sticky;
              top: 0;
              font-size: 28px;
              background: #e9f3fd;
              height: 64px;
              th:nth-child(1) {
                border-left: 2px solid #dcdfe6;
              }
              th {
                border-top: 2px solid #dcdfe6;
              }
            }
            .cont {
              height: 64px;
              font-size: 24px;
            }
            .cont:nth-child(2n-1) {
              background: #f5f5f5;
            }
            .nodata {
              td {
                font-size: 28px;
              }
            }
          }
        }
      }
    }
  }
  .footerBox {
    height: 80px;
    background: #fff;
    display: flex;
    align-items: center;
    .flexBox {
      width: 100vw;
      flex-wrap: nowrap;
      overflow: hidden;
      height: 80%;
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>